import httpClient from '@/shared/utils/http'

function setInterceptorsForHttpClient() {
  httpClient.interceptors.request.use(config => {
    const userInfo = sessionStorage.getItem('userInfo')

    return {
      ...config,
      headers: {
        'Content-Type': 'application/json;chartset=uft-8',
        'Authorization': userInfo == null ? '' : JSON.parse(userInfo).token,
        ...config.headers
      }
    }
  })

  httpClient.interceptors.response.use(res => {
    if (res.status === 200 && res.data.code === 0) {
      return { success: true, data: res.data.result }
    }

    return { success: false, message: res.data.message }
  })
}

export { setInterceptorsForHttpClient }
